import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'revision', 'switch']

    connect() {
        console.log('Switch Number: connected')
        this.attribute        = this.data.get('attribute')
        this.switch_value     = this.data.get('switch-value')
        this.switch_attribute = this.data.get('switch-attribute')
        this.suggest          = this.data.get('suggest')
    }

    changing(event) {
        this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
    }

    switchChange(event) {
        console.log(this.switch_attribute)

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        }
        else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchTarget.name, this.switch_value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    if (that.switch_value === '0') {
                        that.switchTarget.innerHTML = '<i class="fal fa-toggle-off"></i>'
                        that.valueTarget.hidden = true
                    }
                    else {
                        that.switchTarget.innerHTML = '<i class="fal fa-toggle-on"></i>'
                        that.valueTarget.hidden = false
                    }

                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this

        let data = new FormData()
        data.append(this.valueTarget.name, this.suggestionTarget.value)



        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?type=string&attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.valueTarget.value = that.suggestionTarget.value
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    url() {

        console.log('getting url')

        if (this.suggest==='yes') {
            return this.valueTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.valueTarget.closest('form').getAttribute('action');
        }

    }
}