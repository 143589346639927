import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "referSolicitor", "content" ]

  connect() {
    console.log('Connected: Referrals')
  }

  openReferSolicitor(event) {
    this.referSolicitorTarget.hidden = false
  }

  closeReferSolicitor(event) {
    this.referSolicitorTarget.hidden = true
  }


  ////////////////////////////////////// Below is left over  //////////////////////////////


  preventSubmit(event) {
    console.log('Prevent Submit')
    event.preventDefault()
  }

  submitForm(event) {
    let that = this

    console.log('Submit Form so Prevent')
    event.preventDefault()
    // Rails.fire(this.submitTarget, 'submit')

    let data = new FormData(this.submitTarget)
    data.append("commit", event.target.value)

    Rails.ajax({
      type: "post",
      url: this.data.get('create-url'),
      data: data,
      success: function (response) {
        console.log('Submit: Worked')
        console.log(response.form)

        that.contentTarget.innerHTML = that.contentTarget.innerHTML + response.html
        that.submitTarget.outerHTML = response.form


      },
      error: function (response) { console.log('Submit: Failed.')}
    })

    // Redraw the table

  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // data actions for Filtering                                                                                     //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  clear(event) {
    console.log('supertable#clear')

    let url = this.filter_url + '?field=' + event.target.closest("button").name +'&by='
    console.log(`>> url = ${url}`)

    let that = this
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.contentTarget.innerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }


  filterChanged(event) {
    let that = this
    console.log('key pressed')
    let url = this.filter_url + '?field=' + event.target.name + '&by=' + event.target.value
    console.log('Path: ' + url )

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.contentTarget.innerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }

  toggleOn(event) {
    let that = this
    console.log('key pressed')
    console.log('Path: ' + this.url + '?field=' + this.data.get("field") +'&by=' + event.target.value)

    let url = this.url + '?field=' + this.data.get("field") +'&by=1'

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.switchOffTarget.hidden = true
        that.switchOnTarget.hidden = false
        $('#table-body').replaceWith(response.html)
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }

  toggleOff(event) {
    let that = this
    let url = this.url + '?field=' + this.data.get("field") +'&by=0'

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.switchOffTarget.hidden = false
        that.switchOnTarget.hidden  = true
        $('#table-body').replaceWith(response.html)
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }
}
