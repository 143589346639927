import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'suggestionText', 'revision', 'switchYes', 'switchNo', 'optional']

    connect() {
        // console.log('Switch Number: connected')
        this.attribute        = this.data.get('attribute')
        this.switch_value     = this.data.get('switch-value')
        this.switch_attribute = this.data.get('switch-attribute')
        this.suggest          = this.data.get('suggest')
    }

    changing(event) {
        this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
    }



    switchYesClicked(event) {
        console.log('#swtichYesChange')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        }
        else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=1',
                dataType: 'json',
                success: function (response) {
                    that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                    that.optionalTarget.hidden = false

                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '1')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switch_to_yes(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }


    switchNoClicked(event) {
        console.log('#swtichNoChange')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        }
        else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=0',
                dataType: 'json',
                success: function (response) {
                    that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
                    that.optionalTarget.hidden = true

                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '0')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switch_to_no(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.suggestionTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            console.log('NAME ' + this.valueTarget.name + ' Value: ' + this.valueTarget.value)


            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?attribute=" + this.switch_attribute,
            dataType: 'json',
            success: function (response) {
                console.log(response.value)
                that.revisionTarget.hidden = true

                if (response.value === true) {
                    that.switch_to_yes(that)
                }
                else {
                    that.switch_to_no(that)
                }
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    promoteText(event) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?type=string&attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.valueTarget.value = response.value
                that.suggestionTextTarget.hidden = true
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }




    url() {

        console.log('getting url')

        if (this.suggest==='yes') {
            return this.switchYesTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.switchYesTarget.closest('form').getAttribute('action');
        }

    }

    switch_to_yes(that) {
        console.log('#switch_to_yes')
        that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
        that.switchNoTarget.classList.remove('bg-info')
        that.switchNoTarget.classList.remove('text-white')
        that.switchYesTarget.classList.add('bg-info')
        that.switchYesTarget.classList.add('text-white')
        that.optionalTarget.hidden = false

        that.ontrueTarget.hidden = false
        that.onfalseTarget.hidden = true
        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'

    }

    switch_to_no(that) {
        console.log('#switch_to_no')
        that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
        that.switchNoTarget.classList.add('bg-info')
        that.switchNoTarget.classList.add('text-white')
        that.switchYesTarget.classList.remove('bg-info')
        that.switchYesTarget.classList.remove('text-white')
        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
        that.optionalTarget.hidden = true

        that.ontrueTarget.hidden = true
        that.onfalseTarget.hidden = false
        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
    }
}