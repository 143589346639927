import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['newOne']

    connect() {
        console.log('Creator: Connected')

    }

    postForm(event) {
        console.log('We got it!')

        const [_data, _status, xhr] = event.detail

        this.newOneTarget.outerHTML = xhr.response + this.newOneTarget.outerHTML
        // this.commentBodyTarget.value = ''

    }
}