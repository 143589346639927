import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["result"]

    connect() {
        this.panel = this.data.get("for")

        console.log('>>>> Controller Found: Actor Panel Stimulus')
        this.subscription()
        this.request_notes(this.data.get("id"), this.panel)
    }

    subscription() {
        console.log('subscription or not?')
        if (this._subscription == undefined) {
            let _this = this

            _this.id  = _this.data.get("id")
            console.log('Actor ID: ' + this.id + ' for: ' + this.panel)

            this._subscription = consumer.subscriptions.create({ channel: "ActorPanelsChannel", id: _this.data.get("id") }, {
                connected() {
                },
                disconnected() {
                    console.log('Not Connected: ActorPanels')
                },
                received(data) {

                    console.log('We got something: ActorPanels')

                    if (_this.panel == 'tasks') {
                        this._note = document.createElement("div")
                        this._note.innerHTML = (data.note)
                        _this.resultTarget.appendChild(this._note)
                    }

                    if (_this.panel == 'intros-chart') {
                        if (data.monthly_amounts == undefined) {

                        }
                        else {
                            _this.draw_chart(data)
                        }
                    }
                },
            })
        }

        return this._subscription
    }

    draw_chart(data) {

        console.log('draw_chart')

        ctx = document.getElementById('myChart2').getContext('2d');
        myRadarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: data.monthly_dates,
                datasets: [{
                    backgroundColor: '#99f',
                    data: data.monthly_amounts
                }]
            },
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                        }
                    }
                },
                color: [
                    'red'
                ],
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });

    }

    request_notes(id, panel) {
        console.log('>>>> Requesting: ' + id + ' for: ' + panel)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: '/actors/' + id + '/assigned_notes?panel=' + panel,
            dataType: 'json',
            // data: data,
            success: function (response) {
                that.resultTarget.innerHTML = response.html

            },
            error: function (response) { console.log('Setting could not be saved.') }
        })
    }
}