import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import {Turbo} from "@hotwired/turbo-rails";

export default class extends CheckboxSelectAll {

  static targets = [ 'bulkButton' ]
  static values = { selectedPath: String, caseIdent: Number }

  connect() {
    super.connect()
    console.log('Do what you want here.')


    let token = document.querySelector('meta[name="csrf-token"]')
    if (token) {
      this.csrfToken = token.content;
    }
  }

  whatFiles() {
    let that = this
    let data = []

    this.checked.forEach((checkbox) => data.push(checkbox.value))

    fetch(this.selectedPathValue + `?finance_case=${this.caseIdentValue}&idents=${data.join()}`, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
  }

  checkEnabled() {
    if(this.checked.length == 0) {
      this.bulkButtonTarget.hidden = true
    } else {
      this.bulkButtonTarget.hidden = false
    }
  }

  checkAllEnabled() {
    // The count below is before select all has been performed by super! So it looks the wrong way around
    if(this.checked.length == 0) {
      this.bulkButtonTarget.hidden = false
    } else {
      this.bulkButtonTarget.hidden = true
    }
  }


}