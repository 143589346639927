import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["self"]

    connect() {
        console.log('Connected: SuperTableItem')
        this._url = this.data.get('url')
    }

    delete() {
        let that = this

        console.log('Delete')

        Rails.ajax({
            type: 'DELETE',
            url: this._url,
            dataType: 'json',
            success: function (response) {
                that.selfTarget.outerHTML = ''
            },
            error: function (response) { }
        })
    }
}