import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['on', 'off', 'clear']
    static values = { clear: String }

    connect() {
        console.log('Creator: Connected')

    }

    showClear(event) {
        console.log('CaseSelection#showClear')
        this.clearTarget.innerHTML = '<i class="fal fa-minus-square"></i>'
    }

    clearSelection(event) {
        console.log('CaseSelection#cleaeSelection')
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.clearValue,
            dataType: 'json',
            success: function (response) {
                event.target.innerHTML = '<i class="fal fa-square-full" style="opacity:0.5"></i>'

                that.onTargets.forEach(function (element, index) {
                    element.hidden = true
                })

                that.offTargets.forEach(function (element, index) {
                    element.hidden = false
                })
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }
}