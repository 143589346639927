import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'
import swal from "sweetalert2";

export default class extends Controller {
    static targets = ["self", "reason", "complianceDocs", "notApplicableReason"]
    static values = {
        confirm: String, deny: String,
        notApplicableUrl: String,
        userId: String
    }

    connect() {
        console.log('ComplianceItem#connect')
    }

    confirm(event) {
        console.log('ComplianceItem#confirm')

        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.confirmValue,
            dataType: 'json',
            success: function (response) {
                that.selfTarget.outerHTML = response.html

            },
            error: function (response) { console.log('Could not revise')}
        })

    }

    deny(event) {
        console.log('ComplianceItem#deny')
        let that = this

        event.preventDefault()

        let data = new FormData()
        data.append('reason', this.reasonTarget.value)

        Rails.ajax({
            type: 'PATCH',
            url: this.denyValue,
            dataType: 'json',
            data: data,
            success: function (response) {
                that.selfTarget.outerHTML = response.html
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }

    x_notApplicable(event) {
        console.log('ComplianceItem#deny')
        let that = this

        event.preventDefault()

        let data = new FormData()
        data.append('reason', this.notApplicableReasonTarget.value)
        data.append('user', this.userIdValue)

        Rails.ajax({
            type: 'PATCH',
            url: this.notApplicableUrlValue,
            dataType: 'json',
            data: data,
            success: function (response) {
                that.selfTarget.outerHTML = response.html
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })

    }

    seeDocs(event) {
        let url = event.target.name

        console.log(`ComplianceItem#seeDocs ${url}`)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: function (response) {
                that.complianceDocsTarget.innerHTML = response.html

            },
            error: function (response) { console.log('Could not revise')}
        })
    }


}