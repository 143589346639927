import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['paste', 'requiredDocs']
    static values = { missingUrl: String}

    connect() {
        console.log('Creator: Connected')

    }

    x_copyDocument(event) {
        this._image_id = event.target.closest('button').name

        console.log(`Documents#copyDocument ${this._image_id}`)

        console.log(event.target.parentNode.outerHTML)


        this.pasteTargets.forEach((element, index) => {
            console.log('show')
            element.hidden = false
        })

    }

    x_pasteDocument(event) {
        let url = event.target.closest('button').name + `/?upload_id=${this._image_id}`

        console.log(`Documents#pasteDocument copy image ${this._image_id} to ${url}`)

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: function (response) {
                event.target.closest('.card').outerHTML = response.html
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })

        this._image_id = undefined

    }


    pasteDocumentHere(event) {
        let url = event.target.closest('button').name + `/?upload_id=${this._image_id}`
        console.log(`Documents#pasteDocument copy image ${this._image_id} to ${url}`)

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: function (response) {
                event.target.closest('tbody').outerHTML = response.html
                document.getElementById(`compliance-btn-${response.document_id}`).outerHTML = response.compliance_btn
                document.getElementById(`compliance-modal-${response.document_id}`).outerHTML = response.compliance_modal
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })

        this._image_id = undefined

    }

}