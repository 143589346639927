import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "confirmation", "note" ]

  connect() {
    this._url = this.data.get('url')
  }


  invite(event) {
    let that = this

    Rails.ajax({
      type: "get",
      url: this._url + '&applicant_id=' + event.target.name,
      success: function (response) {
        that.confirmationTarget.hidden = false
        that.noteTarget.innerHTML = response.html
      },
      error: function (response) { console.log('Submit: Failed.')}
    })
  }
}
