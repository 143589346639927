import {Controller} from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import {Turbo} from "@hotwired/turbo-rails";

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'revision']
    static values = {type: String, attribute: String, unlocked: Boolean, path: String, hideSave: Boolean}

    connect() {
        // console.log('String Saver: connected')
        this.attribute = this.attributeValue
        this.suggest = this.data.get('suggest')
    }

    changing(event) {
        if (event.keyCode != 9) {
            if (!this.hideSaveValue) {
                this.stateTarget.innerHTML = '<i class="fal fa-save text-danger fa-fw"></i>'
            }
            if (this.suggest === 'yes') {
                this.suggestionTarget.classList.add('border-warning')
            }
        }
    }

    saveChange(event) {
        console.log("StringController#saveChange")

        let that = this
        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change :' + this.suggestionTarget.value)

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.suggestionTarget.value,
                dataType: 'json',
                success: function (response) {
                    if (that.suggestionTarget.value) {
                        that.suggestionTarget.classList.remove('border-warning')
                        that.suggestionTarget.classList.add('border-success')
                    } else {
                        that.suggestionTarget.classList.add('border-warning')
                        that.suggestionTarget.classList.remove('border-success')
                    }
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
                    }
                },
                error: function (response) {
                    that.suggestionTarget.classList.add('border-danger')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.valueTarget.classList.remove('border-warning')
                    that.valueTarget.classList.add('border-success')
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved. [1]')
                }
            })
        }
    }


    saveChangeTurbo(e) {
        let that = this

        let data = new FormData()

        if (this.suggest === 'yes') {
            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.suggestionTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.suggestionTarget.classList.remove('border-warning')
                    that.suggestionTarget.classList.add('border-success')
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved. [2]')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            let url

            if (e.params['usePath'] == 'yes') {
                url = that.pathValue
            } else {
                url = this.url()
            }

            if (that.typeValue) {
                url = url + `?type=${this.typeValue}`
            }

            fetch(url, {
                method: 'PATCH',
                body: data,
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
                }
            })
                .then(r => r.text())
                .then(html => Turbo.renderStreamMessage(html))

            if (!this.hideSaveValue) {
                that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
            }
        }
    }


    promote(event) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?type=string&attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.valueTarget.value = that.suggestionTarget.value
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved. [3]')
            }
        })
    }

    promoteTurbo(event) {
        let that = this

        fetch(this.url() + `/revision_promote?type=${this.typeValue}&attribute=${this.attribute}`, {
            method: 'GET',
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
    }

    removeRevision(event) {
        let that = this

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved. [4]')
            }
        })
    }


    url() {

        console.log('getting url')

        if (this.suggest === 'yes') {
            return this.suggestionTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.valueTarget.closest('form').getAttribute('action');
        }

    }
}