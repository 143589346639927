import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'revision']
    static values = {hideSave: Boolean}

    connect() {
        console.log('String Saver: connected')
        this.attribute = this.data.get('attribute')
        this.suggest = this.data.get('suggest')
        this._path = this.data.get('path')
    }

    changing(event) {
        if (event.keyCode != 9) {
            this.valueTarget.classList.add('border-warning')
            if (!this.hideSaveValue) {
                this.stateTarget.innerHTML = '<i class="fal fa-save text-danger fa-fw"></i>'
            }
        }
    }

    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.valueTarget.classList.remove('border-warning')
                    that.valueTarget.classList.add('border-success')
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved. [1]')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.valueTarget.classList.remove('border-warning')
                    that.valueTarget.classList.add('border-success')
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-fw"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved. [2]')
                }
            })
        }
    }


    promote(event) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?type=string&attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.valueTarget.value = that.suggestionTarget.value
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved. [3]')
            }
        })
    }

    removeRevision(event) {
        let that = this

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved. [4]')
            }
        })
    }


    url() {

        if (this.suggest === 'yes') {
            return this.valueTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.valueTarget.closest('form').getAttribute('action');
        }

    }
}