import {Controller} from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["details"]

    connect() {
        console.log('Connected: Applicant')
        this.revise_url = this.data.get('revise_url')
        console.log('Revise url: ' + this.revise_url)
    }

    revise() {
        console.log('Contribute clicked')

        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.revise_url,
            dataType: 'json',
            success: function (response) {
                console.log('We got something back: ' + response.html)
                that.detailsTarget.outerHTML = response.html
                $('#editApplicant' + response.revision_ident).modal('show')

            },
            error: function (response) { console.log('Could not revise')}
        })

    }

    prepareForm () {
        console.log('fired')

        let data = new FormData()
        let that = this



    }

}