import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'
// import 'bootstrap'

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'revision', 'result']

    connect() {
        this.attribute = this.data.get('attribute')
        this.suggest   = this.data.get('suggest')
    }

    changing(event) {
        // this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
    }

    saveChange(event) {
        let that = this
        let data = new FormData()

        if (this.suggest === 'yes') {
            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'

                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    $('#select-space').append(response.html)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this
        let data = new FormData()

        data.append(this.valueTarget.name, this.suggestionTarget.value)

        Rails.ajax({
            type: 'PATCH',
            url: this.url(),
            dataType: 'json',
            data: data,
            success: function (response) {
                that.valueTarget.value = that.suggestionTarget.value
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                console.log('cleared suggestion')
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }

    removeRevision(event) {
        let that = this

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved. [4]')
            }
        })
    }

    url() {
        if (this.suggest==='yes') {
            return this.valueTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.valueTarget.closest('form').getAttribute('action');
        }
    }
}