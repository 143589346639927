import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
const application = Application.start()

import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
import Chart from 'stimulus-chartjs'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Sortable from '@stimulus-components/sortable'

// Import included controllers
application.register('chart', Chart)
application.register('textarea-autogrow', TextareaAutogrow)
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('sortable', Sortable)

window.bootstrap = bootstrap

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "./controllers"
import $ from 'jquery'

// Configure Stimulus development experience
application.warnings = true
application.debug = false

window.Stimulus = application
