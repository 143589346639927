import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["locked", "entry", "lock"]
    static values = { unlock:Boolean }

    connect() {
        console.log('Locking: Connected')

        if (this.unlockValue) {
            this.unlock()
        }
    }

    unlock() {
        console.log('Contribute clicked')

        this.entryTargets.forEach((element, index) => {
            element.removeAttribute("readonly")
            element.removeAttribute("disabled")
            element.classList.remove('make-disabled')
        })

        this.lockTargets.forEach((element, index) => {
            element.innerHTML = '<i class="fal fa-lock-open-alt fa-fw"></i>'
        })

    }

}