import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["suitable", "table", "chart"]

    connect() {
        console.log('Submitted Chart: connected')
    }

    initialize() {
        this.drawChart('no')
    }

    toggleSuitable() {
        console.log('Click Not Suitable')

        this.not_suitable = this.data.get('not_suitable')

        if (this.not_suitable === 'yes') {
            this.suitableTarget.innerHTML = '<i class="fad fa-toggle-off"></i> Show Not Suitable'
            this.not_suitable = 'no'
            this.data.set('not_suitable', 'no')
        } else {
            this.suitableTarget.innerHTML = '<i class="fad fa-toggle-on" style="--fa-secondary-color: #4ad2c2;"></i> Show Not Suitable'
            this.not_suitable = 'yes'
            this.data.set('not_suitable', 'yes')
        }

        this.drawChart(this.data.get('not_suitable'))
    }

    drawChart(not_suitable) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.data.get('url') + '?not_suitable=' + not_suitable,
            dataType: 'json',

            success: function (response) {

                console.log(response.dates)
                console.log(response.values)

                // let ctx = document.getElementById('myChartSubsNew').getContext('2d');

                let ctx = that.chartTarget.getContext('2d');

                let myRadarChart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels:response.dates,
                        datasets: [{
                            backgroundColor: '#9f9',
                            data: response.values
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return tooltipItem.yLabel;
                                }
                            }
                        },
                        color: [
                            'red'
                        ],
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero:true
                                }
                            }]
                        }
                    }
                });


                that.tableTarget.innerHTML = response.table
            },
            error: function (response) { console.log('Search: Failed.')}
        })
    }

}