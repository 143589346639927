import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'
import jquery from 'jquery';
window.$ = window.jquery = jquery;
// import 'bootstrap'

export default class extends Controller {
    static targets = ['option']

    connect() {
        console.log('Connected: Enquiry State')
        this.attribute = this.data.get('attribute')
        this.suggest   = this.data.get('suggest')
    }

    open() {
        console.log('Show First Modal')
        console.log(this.optionTarget)

        this.optionTarget.classList.add('modal-open')
        this.optionTarget.setAttribute("style", "display: block;")
        this.optionTarget.classList.add("show")
        document.body.innerHTML += '<div class="modal-backdrop fade show"></div>'
    }

    close() {
        this.optionTarget.classList.remove("modal-open")
        this.optionTarget.removeAttribute("style")
        this.optionTarget.classList.remove("show")
        document.getElementsByClassName("modal-backdrop")[0].remove()
    }


    //////////////

    changing(event) {
        // this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
    }

    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest==='yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'

                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {

                    $('#select-space').append(response.html)


//                        that.resultTarget.innerHTML = response.html
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this

        let data = new FormData()
        data.append(this.valueTarget.name, this.suggestionTarget.value)

        Rails.ajax({
            type: 'PATCH',
            url: this.url(),
            dataType: 'json',
            data: data,
            success: function (response) {
                that.valueTarget.value = that.suggestionTarget.value
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) { console.log('Setting could not be saved.')}
        })

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.attribute,
            dataType: 'json',
            success: function (response) {
                console.log('cleared suggestion')
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    url() {

        console.log('getting url')

        if (this.suggest==='yes') {
            return this.valueTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.valueTarget.closest('form').getAttribute('action');
        }

    }
}