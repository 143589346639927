import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'state', 'suggestion', 'revision', 'switchYes', 'switchNo', 'ontrue', 'onfalse']
    static values = {hideSave: Boolean}

    connect() {
        console.log('Switch Number: connected')
        this.attribute = this.data.get('attribute')
        this.switch_value = this.data.get('switch-value')
        this.switch_attribute = this.data.get('switch-attribute')
        this.suggest = this.data.get('suggest')
    }

    changing(event) {
        if (!this.hideSaveValue) {
            this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
        }
    }

    switchYesClicked(event) {
        console.log('#swtichYesChange')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=1',
                dataType: 'json',
                success: function (response) {
                    that.switch_to_yes(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '1')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switch_to_yes(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    switchYesClickedTurbo(event) {
        console.log('#swtichYesChangeTurbo')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            fetch(this.url() + "?attribute=" + this.switch_attribute + '&value=1', {
                method: 'GET',
                body: data,
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
                }
            })
                .then(r => r.text())
                .then(html => Turbo.renderStreamMessage(html))
            that.switch_to_yes(that)

        } else {

            data.append(this.switchYesTarget.name, '1')

            fetch(this.url(), {
                method: 'PATCH',
                body: data,
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
                }
            })
                .then(r => r.text())
                .then(html => Turbo.renderStreamMessage(html))
            that.switch_to_yes(that)


        }
    }


    switchNoClicked(event) {
        console.log('#swtichNoChange')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=0',
                dataType: 'json',
                success: function (response) {
                    that.switch_to_no(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '0')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switch_to_no(that)
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    switchNoClickedTurbo(event) {
        console.log('#swtichNoChangeTurbo')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            fetch(this.url() + "?attribute=" + this.switch_attribute + '&value=0', {
                method: 'GET',
                body: data,
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
                }
            })
                .then(r => r.text())
                .then(html => Turbo.renderStreamMessage(html))
            that.switch_to_no(that)

        } else {

            data.append(this.switchYesTarget.name, '0')

            fetch(this.url(), {
                method: 'PATCH',
                body: data,
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
                }
            })
                .then(r => r.text())
                .then(html => Turbo.renderStreamMessage(html))
            that.switch_to_no(that)
        }
    }


    switchChange(event) {
        console.log('switch#switchChange')
        console.log(this.switch_attribute)

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    that.switchTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            console.log('Switch: ' + this.switch_value)

            data.append(this.switchTarget.name, this.switch_value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    if (that.switch_value === '0') {
                        that.switchTarget.innerHTML = '<i class="fal fa-toggle-off fa-lg"></i>'
                        that.ontrueTarget.hidden = true
                    } else {
                        that.switchTarget.innerHTML = '<i class="fal fa-toggle-on fa-lg"></i>'
                        that.ontrueTarget.hidden = false
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?attribute=" + this.switch_attribute,
            dataType: 'json',
            success: function (response) {

                console.log(response.value)
                that.revisionTarget.hidden = true

                if (response.value === true) {
                    that.switch_to_yes(that)
                } else {
                    that.switch_to_no(that)
                }
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    url() {

        console.log('getting url')

        if (this.suggest === 'yes') {
            return this.switchYesTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.switchYesTarget.closest('form').getAttribute('action');
        }

        console.log('Got url')

    }

    switch_to_yes(that) {
        console.log('#switch_to_yes')
        that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'

        that.ontrueTarget.hidden = false
        that.onfalseTarget.hidden = true

        if (!that.hideSaveValue) {
            that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
        }
    }

    switch_to_no(that) {
        console.log('#switch_to_no')
        that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'

        that.ontrueTarget.hidden = true
        that.onfalseTarget.hidden = false

        if (!that.hideSaveValue) {
            that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
        }

    }
}