import {Controller} from "@hotwired/stimulus"

// import consumer from "channels/consumer"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["result"]
    static values = { url: String }

    connect() {
        let myChart = null
        console.log(`>>>>>>>> ${this.urlValue}`)
        this.drawChart()
    }

    drawChart() {
        let that = this

        if (this.myChart !== undefined) {
            console.log('BySource#drawChart: destroy old chart')
            this.myChart.destroy()
            this.canvas = document.getElementById('bySource')
            this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
        }

        setTimeout(function(){
            Rails.ajax({
                type: 'GET',
                url: that.urlValue,
                dataType: 'json',
                success: function (data) {

                    console.log(`drawChart: x = ${data.x_axis} y = ${data.y_axis}`)

                    this.ctx = document.getElementById('bySource').getContext('2d');
                    that.myChart = new Chart(this.ctx, {
                        type: 'bar',
                        data: {
                            labels: JSON.parse(data.x_axis),
                            datasets: [{
                                backgroundColor: '#99f',
                                data: JSON.parse(data.y_axis)
                            }]
                        },
                        options: {
                            legend: {
                                display: false
                            },
                            tooltips: {
                                callbacks: {
                                    label: function(tooltipItem) {
                                        return tooltipItem.yLabel;
                                    }
                                }
                            },
                            color: [
                                'red'
                            ],
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero:true
                                    }
                                }]
                            }
                        }
                    });
                },
                error: function (response) {
                    console.log('filter failed')
                }
            })
        }, 2000);
    }
}